import * as React from 'react'
import { StoreContext } from '../../store/storeContext'
import Snackbar from '@mui/material/Snackbar';
import { getDropdownsOptions, GetDropOffInTransitByContainer } from '../../services/services'
import dayjs from 'dayjs'

import { createDropOff, updateDropOff } from '../../services/services'
import { Update } from '@mui/icons-material';
const useModalController = () => {
    const { openModal, setOpenModal, update, setUpdate, user, dropOffObject, setDropOffObject, snackbarAction, setSnackbarAction, locations, setLocations, sizeTypes, setSizeTypes, sslList, setSslList } = React.useContext(StoreContext)

    const [loading, setLoading] = React.useState(true)
    const [snackbarActionSuccess, setSnackbarActionSuccess] = React.useState(false)
    const [snackbarActionError, setSnackbarActionError] = React.useState(false)
    const [minDateDeliver, setMinDateDeliver] = React.useState(dayjs(new Date()))
    const [minDateExpectedArrival, setMinDateExpectedArrival] = React.useState(dayjs(new Date()))

    const [dropOffId, setDropOffId] = React.useState('')
    const [saving, setSaving] = React.useState(false)

    const [buttonSaveDisabled, setButtonSaveDisabled] = React.useState(null)
    const [validationObject, setValidationObject] = React.useState({
        container_tmp_numberValidation: false,
        locationValidation: false,
        scacValidation: false,
    })



    const saveDropOff = () => {

        if (Object.values(validationObject).includes(false)) {
            setButtonSaveDisabled(false)
        }
        else {
            setSaving(true)
            setSnackbarActionSuccess(false)
            setSnackbarActionError(false)
            setButtonSaveDisabled(true)
            dropOffObject.expectedArrivalDate = dropOffObject.expectedArrivalDate.toISOString()
            dropOffObject.deliveryDate = dropOffObject.deliveryDate.toISOString()
            dropOffObject.ssl ? (dropOffObject.ssl = dropOffObject.ssl.scacCode) : null
            if (update) {

                updateDropOff(dropOffObject).then((response) => {
                    if (response.data.success === true) {
                        setDropOffObject({
                            ...dropOffObject,
                            location: null,
                            scacCode: null,
                            carrier: null,
                            containerNumber: null,
                            ssl: null,
                            sizeType: null,
                            chassisNumber: null,
                            keepChassis: false,
                            expectedArrivalDate: dayjs(new Date()),
                            userName: dropOffObject.userName,
                            foodGrade: false,
                            tmp: null,
                            deliveryDate: dayjs(new Date()),
                            deliveryLocation: null,
                            comment: null,
                        })
                        setDropOffId(dropOffObject.id)

                        setSnackbarActionSuccess(true)
                        setTimeout(() => {
                            const fetchData = new CustomEvent('fetchPendingSt', { detail: { fetch: true } });
                            window.dispatchEvent(fetchData);
                            setOpenModal(false)
                            setSnackbarActionSuccess(false)
                            setSaving(false)
                            setUpdate(false)
                        }, 1)

                    }
                    else {
                        setDropOffId(response.data.message)
                        setDropOffObject({ ...dropOffObject, expectedArrivalDate: dayjs(dropOffObject.expectedArrivalDate), deliveryDate: dayjs(dropOffObject.deliveryDate) });
                        setSaving(false)
                        setSnackbarActionError(true)
                        setTimeout(() => {
                            setSnackbarActionError(false)

                        }, 3000)
                        setUpdate(false)
                    }
                })
            } else {
                createDropOff(dropOffObject).then((response) => {
                    if (response.data.success === true) {

                        setDropOffObject({
                            ...dropOffObject,
                            location: null,
                            scacCode: null,
                            carrier: null,
                            containerNumber: null,
                            ssl: null,
                            sizeType: null,
                            chassisNumber: null,
                            keepChassis: false,
                            userName: dropOffObject.userName,
                            expectedArrivalDate: dayjs(new Date()),
                            foodGrade: false,
                            tmp: null,
                            deliveryDate: dayjs(new Date()),
                            deliveryLocation: null,
                            comment: null,
                        })
                        setDropOffId(response.data.dropOffCreated.receiptNumber)
                        setSnackbarActionSuccess(true)
                        setTimeout(() => {
                            const fetchData = new CustomEvent('fetchPendingSt', { detail: { fetch: true } });
                            window.dispatchEvent(fetchData);
                            setOpenModal(false)
                            setSnackbarActionSuccess(false)
                            setSaving(false)
                        }, 1)
                    }
                    else {
                        setDropOffId(response.data.message)
                        setDropOffObject({ ...dropOffObject, expectedArrivalDate: dayjs(dropOffObject.expectedArrivalDate), deliveryDate: dayjs(dropOffObject.deliveryDate) });
                        setSaving(false)
                        setSnackbarActionError(true)
                        setTimeout(() => {
                            setSnackbarActionError(false)
                        }, 3000)

                    }
                })
            }

        }
    }

    React.useEffect(() => {
        getDropdownsOptions().then((response) => {
            setLocations(response.locations)
            setSizeTypes(response.sizeTypes)
            setSslList(response.sslList)
            setLoading(false)

        })
    }, [])

    React.useEffect(() => {
        if (sslList.length > 0) {
            window.addEventListener('editDropOff', (e) => {
                cleanDropOff()
                setSaving(true)
                setOpenModal(true);
                setUpdate(true)
                GetDropOffInTransitByContainer(e.detail.item.cont_trl_num).then((response) => {
                    if (response.data.dropOff) {
                        const dropOffObject = response.data.dropOff

                        setMinDateDeliver(dayjs(new Date(dropOffObject.deliveryDate)))
                        setMinDateExpectedArrival(dayjs(new Date(dropOffObject.deliveryDate)))
                        const combossL = sslList.find((ssl) => ssl.scacCode === dropOffObject.ssl)
                        setDropOffObject({
                            ...dropOffObject,
                            id: dropOffObject.id,
                            location: dropOffObject.location,
                            scacCode: dropOffObject.scac,
                            carrier: dropOffObject.carrier,
                            containerNumber: dropOffObject.container,
                            ssl: combossL,
                            sizeType: dropOffObject.sizeType,
                            chassisNumber: dropOffObject.chassis,
                            keepChassis: dropOffObject.keepChassis,
                            expectedArrivalDate: dayjs(new Date(dropOffObject.expectedArrivalDate)),
                            foodGrade: dropOffObject.foodGrade,
                            tmp: dropOffObject.tmp,
                            deliveryDate: dayjs(new Date(dropOffObject.deliveryDate)),
                            deliveryLocation: dropOffObject.deliveryLocation,
                            comment: dropOffObject.comment,
                        }
                        )
                        setSaving(false)
                    }
                    else {
                        const combossL = sslList.find((ssl) => ssl.scacCode === e.detail.item.ssl)
                        setMinDateDeliver(dayjs(new Date(dropOffObject.deliveryDate)))
                        setMinDateExpectedArrival(dayjs(new Date(dropOffObject.deliveryDate)))
                        setDropOffObject({
                            ...dropOffObject,
                            id: e.detail.item.Id,
                            location: e.detail.item.location === 'N/A' ? null : e.detail.item.location,
                            scacCode: e.detail.item.scac === 'N/A' ? null : e.detail.item.scac,
                            carrier: null,
                            containerNumber: e.detail.item.cont_trl_num === 'N/A' ? null : e.detail.item.cont_trl_num,
                            ssl: combossL,
                            sizeType: e.detail.item.s_t === 'N/A' ? null : e.detail.item.s_t,
                            chassisNumber: e.detail.item.chassis_n === 'N/A' ? null : e.detail.item.chassis_n,
                            keepChassis: false,
                            expectedArrivalDate: dayjs(new Date(e.detail.item.arrival === 'N/A' ? new Date() : e.detail.item.arrival)),
                            foodGrade: false,
                            userName: dropOffObject.userName,
                            tmp: null,
                            deliveryDate: dayjs(new Date()),
                            deliveryLocation: null,
                            comment: null,
                        }
                        )
                        setSaving(false)
                    }
                }
                )

            },);
        }
    }, [sslList])


    React.useEffect(() => {
        const validations = {
            container_tmp_numberValidation: (dropOffObject.containerNumber !== null && dropOffObject.containerNumber.length >= 10) || dropOffObject.tmp !== null,
            locationValidation: dropOffObject.location ? true : false,
            scacValidation: dropOffObject.scacCode ? true : false,
        };
        setValidationObject(validations);
    }, [dropOffObject]);


    const cleanDropOff = () => {
        setUpdate(false)
        setDropOffObject({
            ...dropOffObject,
            location: null,
            scacCode: "SNEA",
            carrier: null,
            containerNumber: null,
            ssl: null,
            sizeType: null,
            chassisNumber: null,
            keepChassis: false,
            userName: dropOffObject.userName,
            expectedArrivalDate: dayjs(new Date()),
            foodGrade: false,
            tmp: null,
            deliveryDate: dayjs(new Date()),
            deliveryLocation: null,
            comment: null,
        })
    }

    React.useEffect(() => {
        if (!update) {
            if (openModal) {
                cleanDropOff();
            }
        }

    }, [openModal]);


    return {
        openModal,
        setOpenModal,
        loading,
        locations,
        sizeTypes,
        sslList,
        buttonSaveDisabled,
        dropOffObject,
        setDropOffObject,
        setButtonSaveDisabled,
        saveDropOff,
        validationObject,
        snackbarAction,
        setSnackbarAction,
        saving,
        snackbarActionSuccess,
        snackbarActionError,
        dropOffId,
        minDateDeliver,
        setMinDateDeliver,
        minDateExpectedArrival,
        setMinDateExpectedArrival,
        cleanDropOff,
        update
    }
}

export default useModalController