import axios from "axios";
import appInsight from "./appInsight";
const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
    "Content-Type": "application/json",
};
const headersDrayPower = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY_DRAYPOWER,
    "Content-Type": "application/json",
}
export const getDropdownsOptions = async () => {

    try {
        const responseLocations = await axios.get(process.env.REACT_APP_GET_LOCATIONS_URL);
        const responseSizeTypes = await axios.get(process.env.REACT_APP_GET_SIZE_TYPE_URL);
        const responseSslList = await axios.get(process.env.REACT_APP_GET_SSL_LIST_URL);
        responseLocations.data.shift();
        const response = {
            locations: responseLocations.data,
            sizeTypes: responseSizeTypes.data,
            sslList: responseSslList.data
        }
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export const createDropOff = async (data) => {

    try {
        const config = {
            headers: headers,
            method: 'post',
            url: process.env.REACT_APP_CREATE_DROP_OFF_URL,
            data: data
        }
        const response = await axios(config);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export const checkUser = async (entity) => {

    try {
        const config = {
            headers: headersDrayPower,
            method: 'post',
            url: process.env.REACT_APP_CHECK_USER_URL,
            data: entity
        }
        const response = await axios(config);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}


export const GetDropOffInTransitByContainer = async (containerNumber) => {

    try {
        const config = {
            headers: headers,
            method: 'get',
            url: process.env.REACT_APP_GET_DROP_OFF_IN_TRANSIT + containerNumber,

        }
        const response = await axios(config);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export const updateDropOff = async (data) => {

    try {
        const config = {
            headers: headers,
            method: 'post',
            url: process.env.REACT_APP_UPDATE_DROP_OFF_INTRANSIT,
            data: data
        }
        const response = await axios(config);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export const get = async (url, params, username, eventName) => {
    const config = {
        headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
            "Content-Type": "application/json",
        },
        method: 'get',
        url: url,
        timestamp: new Date().getTime(),
        params: params
    }
    const response = await axios(config);
    appInsight.actions.sendAppInsightEvent({ commit: null }, {
        properties: {
            eventName: 'DOff' + eventName,
            success: response.status === 200 ? true : false,
            function: 'get',
            payload: params,
            response: response,
            latency: new Date().getTime() - config.timestamp,
            user: username
        }
    });
}

export const post = async (url, params, username, eventName, nameOfData) => {
    const config = {
        headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
            "Content-Type": "application/json",
        },
    };
    try {
        const response = await axios.post(url, params, config);
        appInsight.actions.sendAppInsightEvent({ commit: null }, {
            properties: {
                eventName: 'DOff' + eventName,
                success: response.status === 200 ? true : false,
                function: 'post',
                payload: params,
                response: response,
                latency: response.config.timestamp ? new Date().getTime() - response.config.timestamp : null,
                user: username
            }
        });
        return response; // Importante: devolver la respuesta para que se pueda usar donde se llama a la función `post`
    }
    catch (error) {
        appInsight.actions.sendAppInsightException({ commit: null }, {
            properties: {
                error: error.message,
                success: false,
                function: url,
                payload: params,
                user: username
            }
        });
        throw error; // Importante: relanzar el error para que pueda ser manejado donde se llama a la función `post`
    }
}
